import type { NextPage } from "next";
import Error from "next/error";

const NotFoundPage: NextPage = () => {
  return (
    <div className="flex-1">
      <Error statusCode={404} />
    </div>
  );
};

export default NotFoundPage;
